@import url(https://fonts.googleapis.com/css2?family=Irish+Grover&family=Space+Mono:wght@400;700&display=swap);
html{
  font-size: 16px;
}

body {
  margin: 0;
  font-family: roboto,Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	font-family: "Space Mono";
  font-weight: 500;
  color: #fff;
  background: url(/static/media/bg.793d0c12.webp) #000;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

main{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.info{
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 25px 25px;
  text-align: center;
}

.infobox {
  background-color: rgba(0,0,0,0.2);
  max-width: 900px;
  margin-bottom: 50px;
}

.info span {
  color: #C4B5FD;
  font-weight: 800;
}

main button {
	padding: 50px;
}

.balance{
  margin-bottom: 2rem;
  margin-top: -3rem;
}

.cost{
  margin-top: 1rem;
  font-size: 1rem;
  text-align: center;
}

.title{
  text-align: center;
  font-size: 2rem;
  line-height: 1;
	font-family: roboto,Sans-serif;
  font-size: 72px;
  font-weight: 600;
  letter-spacing: -2px;
}

.App {
text-align: center;
}

